<template>
    <div class="regions">
        <BaseCloseIcon class="icon" @action="goToMainPage" />
        <div class="search">
            <h2 class="choose-location">Выберите город из списка:</h2>
            <BaseInput 
                outlined
                rounded
                label="Город"
                validation="autocomplete" 
                autocomplete
                width="320"
                id="location" 
                :autocompleteData="regions.autocompleteData"
                @sendData="regions.chooseCurrentLocation($event)"
            />
            <BaseButton
                value="сохранить"
                animated bbt ca
                @action="regions.setCurrentLocation"
                class="btn"
            />
        </div>
        <div class="regions-list" v-if="screens.regions">
            <div class="letter-block" v-for="letter, index in regions.regionsList" :key="index">
                <h2 class="letter">{{ letter.letter }}</h2>
                <div
                    class="region"
                    v-for="region, index in letter.regions"
                    :key="index"
                    @click="regions.filterCitiesByRegion(region)"
                >{{ region.name }}</div>
            </div>
            <BaseBackIcon class="back-icon" @click="goToMainPage"/>
        </div>
        <div class="cities-list" v-if="screens.cities">
            <div class="city"
                :class="{ 'primary': city.primary, 'secondary': city.secondary }"
                v-for="city, index in regions.currentCities"
                :key="index"
                @click="regions.setCity(city)"
            >{{ city.name }}</div>
            <BaseBackIcon class="back-icon" @click="regions.backToRegions"/>
        </div>
    </div>
</template>

<script>
    import { useRouter } from "vue-router";
    import { useStore } from "vuex";
    import { computed, reactive } from 'vue';
    import cities from "@/database/cities.js";
    import regionsListAPI from "@/database/regions.js";

    export default {
        setup(){
            const router = useRouter();
            const store = useStore();

            const goToMainPage = () => router.push('/')
            const regions = reactive({
                autocompleteData: cities,
                regionsList: regionsListAPI,
                currentCities: null,
                isCitiesListOpened: true,
                choosenLocation: null,
                isLocationChoosen: computed(() => {
                    if(regions.choosenLocation){
                        return false
                    } else {
                        return true
                    }
                }),
                chooseCurrentLocation: (data) => regions.choosenLocation = data,
                setCurrentLocation: () => {
                    store.dispatch('setLocation', regions.choosenLocation);
                    localStorage.setItem('ermUserLocation', JSON.stringify(regions.choosenLocation))
                    router.push("/")
                },
                setCity: (city) => {
                    store.dispatch('setLocation', city);
                    localStorage.setItem('ermUserLocation', JSON.stringify(city.name))
                    router.push("/")
                },
                filterCitiesByRegion: (region) => {
                    regions.currentCities = region.cities;
                    screens.regions = false;
                    screens.cities = true;
                },
                backToRegions: () => {
                    screens.regions = true;
                    screens.cities = false;
                    regions.currentCities = null;
                }


            })

            const screens = reactive({
                regions: true,
                cities: false
            })

            return {
                goToMainPage,
                regions,
                screens
            }
        }
    }
</script>

<style lang="scss" scoped>
    .regions{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 3em 0;

        .icon{
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }

        .search{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h2{
                margin-bottom: 2em;
            }
            .btn{
                margin-top: 2em;
                font-size: 1.2em;
            }
        }
        .regions-list{
            padding: 3em 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            .letter-block{
                width: 250px;
                text-align: center;
                margin-bottom: 1em;
                .letter{
                    margin-bottom: 1em;
                }
                .region{
                    margin-bottom: 0.4em;
                    cursor: pointer;
                    &:hover{
                        color: $accent-dark;
                        font-weight: bold;
                    }
                }
            }
            .back-icon{
                width: 100%;
                margin-top: 1em;
                cursor: pointer;
            }
        }
        .cities-list{
            text-align: center;
            padding: 2em;
            .city{
                margin-bottom: 0.4em;
                cursor: pointer;
                &:hover{
                    color: $accent-dark;
                    font-weight: bold;
                }
            }
            .primary{
                font-size: 1.5em;
                font-weight: bold;
            }
            .secondary{
                font-weight: bold;
                font-size: 1.2em;
            }
            .back-icon{
                margin-top: 1em;
                cursor: pointer;
            }
        }
    }
</style>